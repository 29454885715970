import { combineReducers, configureStore } from '@reduxjs/toolkit'
import categories from './slices/categorySlice'
import layout from './slices/layoutSlice'
import sections from './slices/sectionsSlice'
import user from './slices/userSlice'
import pages from './slices/pagesSlice'
import changeState from './slices/sideBar'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

const persistConfig = {
  key: 'root',
  storage,
}
const combineReducer = combineReducers({
  layout,
  categories,
  sections,
  user,
  pages,
  changeState,
})
const persistedReducer = persistReducer(persistConfig, combineReducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
})

export const persistor = persistStore(store)
