import React, { Fragment, useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { deleted, get } from 'src/utils/api_Helper'
import { ToastContainer, toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setResponse, setServerMessagestt } from 'src/redux/slices/layoutSlice'
import { FaEdit } from 'react-icons/fa'
import { RiDeleteBinLine } from 'react-icons/ri'
import {
  Button,
  Chip,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import { setLoading, setLocation } from 'src/redux/slices/categorySlice'
import { pages, setCategory, setCategoryId } from 'src/redux/slices/pagesSlice'
import { cities } from 'src/redux/slices/userSlice'
import LoaderUI from 'src/Loader/Loader'

const Cities = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [open, setOpen] = React.useState(false)

  const pageData = useSelector((state) => state.pages.pages)
  const { Listing } = useSelector((state) => state.user)

  const [list, setList] = useState([])
  const [delId, setDelId] = useState(null)
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    getList()
  }, [dispatch])

  useEffect(() => {
    Listing &&
      Listing.length > 0 &&
      Listing.map((item, index) => {
        setList((preState) => [
          ...preState,
          {
            id: Math.random(),
            ...item,
          },
        ])
      })
    return () => {
      setList([])
    }
  }, [Listing])

  const getList = () => {
    setLoader(true)
    dispatch(cities())
      .then((res) => {
        setLoader(false)
      })
      .catch((err) => {})
  }

  const columns = [
    { field: 'name', headerName: 'City Name', width: 220 },

    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        return (
          <Fragment>
            <IconButton
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate(`/admin/editCity/${params.row._id}`, {
                  state: { id: id, item: params.row },
                })
              }}
            >
              <FaEdit />
            </IconButton>

            {/* <IconButton
              variant="text"
              color="error"
              onClick={() => {
                setOpen(true)
                setDelId(params.row._id)
                // setType(params.row.item.type)
              }}
            >
              <RiDeleteBinLine />
            </IconButton> */}
          </Fragment>
        )
      },
    },
  ]

  const handleClose = () => {
    setOpen(false)
  }
  const handleAgree = () => {
    setLoader(true)
    deleted(`/deleteCity/${delId}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch(setResponse(res))
          getList()
          setLoader(false)
        }
      })
      .catch((err) => {
        setLoader(false)

        toast.error(err.response.data.message)
      })
    setOpen(false)
  }

  return (
    <React.Fragment>
      {loader && <LoaderUI />}
      <div style={{ height: 690, width: '100%', padding: '13px' }}>
        <span className="col-sm-2 col-form-label text-center w-100 mb-3 fw-bold fs-3">
          Cities -- Listing
        </span>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <button
            type="button"
            className="btn btn-info "
            style={{ color: 'white' }}
            onClick={() => {
              // setLocation(null)
              navigate('/admin/addCity')
            }}
          >
            Add new
          </button>
        </div>
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          components={{
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Data not found
              </Stack>
            ),
          }}
        />
      </div>

      {/* Modal */}
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Page'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete the City?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleAgree} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}

export default Cities
