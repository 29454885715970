import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#764abc',
    },
  },
  background: {
    default: '#fff',
  },
})

export default theme
