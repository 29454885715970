import React, { Fragment, useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { deleted } from 'src/utils/api_Helper'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setResponse } from 'src/redux/slices/layoutSlice'
import { FaEdit } from 'react-icons/fa'
import { RiDeleteBinLine } from 'react-icons/ri'
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import { pages, setCategoryId } from 'src/redux/slices/pagesSlice'

const Sections = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const pageData = useSelector((state) => state.pages.pages)
  const [loader, setloader] = useState(false)
  const [list, setList] = useState([])
  const [delId, setDelId] = useState(null)
  const [type, setType] = useState(null)
  const [pageDatas, setPageData] = useState()
  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    getList()
  }, [dispatch, pageNumber])


  useEffect(() => {
    const { page, pageSize, totalPages, totalRecords } = pageData;
    setPageData({ page, pageSize, totalPages, totalRecords })

    setloader(true)
    pageData &&
      pageData?.pages?.length > 0 &&
      pageData?.pages?.map((item, index) => {

        item?.page?.map((subItem, i) => {
          setList((preState) => [
            ...preState,
            {
              id: Math.random(),
              _id: item._id,
              cate_id: item.category,
              category: item.slug,
              alt: item.alt,
              location:
                item?.location && item?.location[0]?.value
                  ? item.location.map((preSubItem) => preSubItem.value)
                  : item.location.map((preSubItem) => preSubItem),
              sectionType: subItem.type,
              item: subItem,
            },
          ])
        })
      }, setloader(false))

    return () => {
      setList([])
    }
  }, [pageData])

  const getList = () => {
    setloader(true)
    dispatch(pages(pageNumber))
      .then((res) => {
        setloader(false)
      })
      .catch((err) => {
        setloader(false)
      })
    setloader(false)
  }

  const columns = [
    { field: 'category', headerName: 'Category Name', width: 220 },
    { field: 'location', headerName: 'Location', width: 220 },
    {
      field: 'sectionType',
      headerName: 'Section Type',
      width: 220,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 220,
      renderCell: (params) => {
        const id = params.getValue(params.id, 'id')
        return (
          <Fragment>
            <IconButton
              variant="outlined"
              color="primary"
              onClick={() => {
                navigate(`/admin/editSection/${params.row._id}`, {
                  state: { id: id, item: params.row },
                })

                setCategoryId(params.row.cate_id)
              }}
            >
              <FaEdit />
            </IconButton>

            <IconButton
              variant="text"
              color="error"
              onClick={() => {
                setOpen(true)
                setDelId(params.row._id)
                setType(params.row.item.type)
              }}
            >
              <RiDeleteBinLine />
            </IconButton>
          </Fragment>
        )
      },
    },
  ]

  const handleClose = () => {
    setOpen(false)
  }
  const handleAgree = () => {
    setloader(true)
    deleted(`/deletePage?id=${delId}&type=${type}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch(setResponse(res))
          getList()
          setloader(false)
        }
      })
      .catch((err) => {
        setloader(false)

        toast.error(err.response.data.message)
      })
    setOpen(false)
  }

  return (
    <React.Fragment>
      <div style={{ height: 690, width: '100%', padding: '13px' }}>
        <span className="col-sm-2 col-form-label text-center w-100 mb-3 fw-bold fs-3">
          Pages/Sections -- Listing
        </span>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <button
            type="button"
            className="btn btn-info "
            style={{ color: 'white' }}
            onClick={() => {
              // setLocation(null)
              navigate('/admin/addSection')
            }}
          >
            Add new
          </button>
        </div>
        <DataGrid
          rows={list}
          columns={columns}
          pageSize={10}
          loading={loader}
          rowsPerPageOptions={[10]}
          onPageChange={(pageNumber) => {
            setPageNumber(pageNumber + 1)
          }}
          rowCount={pageDatas?.totalRecords}
          components={{
            NoRowsOverlay: () =>
              !loader && (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  Data not found
                </Stack>
              ),
          }}
        />
      </div>

      {/* Modal */}
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open alert dialog
        </Button> */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Page'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure to delete the section?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleAgree} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment>
  )
}

export default Sections
