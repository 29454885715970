import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  serverMessage: null,
  statusCode: null,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setResponse: (state, action) => {
      state.serverMessage = action.payload.data.message
      state.statusCode = action.payload.data.status
    },
    setResponseV2: (state, action) => {
      state.serverMessage = action.payload.message
      state.statusCode = action.payload.status
    },
    setServerMessagestt: (state, action) => {
      state.serverMessage = action.payload
      state.statusCode = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setResponse, setServerMessagestt, setResponseV2 } = layoutSlice.actions

export default layoutSlice.reducer
