import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './App.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { setServerMessagestt } from './redux/slices/layoutSlice'
import Register from './pages/Auth/Register'
import { Box, LinearProgress } from '@mui/material'
import Sections from './pages/admin pages/Section/Sections'
import Cities from './pages/admin pages/City/Cities'
import { pageCategoriesList } from './redux/slices/categorySlice'
// react responsive
const BlogDetails = React.lazy(() => import('./components/BlogDetails'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const AddCity = React.lazy(() => import('./pages/admin pages/City/AddCity'))
const Login = React.lazy(() => import('./pages/Auth/Login'))
const Welcome = React.lazy(() => import('./pages/Welcome'))
const AddAccesptedLead = React.lazy(() => import('./pages/admin pages/AddAccesptedLead'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Lead = React.lazy(() => import('./pages/admin pages/Lead/Lead'))
const Vendor = React.lazy(() => import('./pages/admin pages/Vendor/Vendor'))
const Blog = React.lazy(() => import('./pages/admin pages/Blogs/Blog'))
const BlogForn = React.lazy(() => import('./pages/admin pages/Blogs/BlogForm'))
const VendorForn = React.lazy(() => import('./pages/admin pages/Vendor/VendorForm'))
const RechargeForm = React.lazy(() => import('./pages/admin pages/Recharge/RechargeForm'))
const PasswordForm = React.lazy(() => import('./pages/admin pages/Password/PasswordForm'))
const CategoriesForm = React.lazy(() => import('./pages/admin pages/category/CategoriesForm'))
const Categories = React.lazy(() => import('./pages/admin pages/category/Categories'))
const SubCategories = React.lazy(() => import('./pages/admin pages/subcategory/SubCategories'))
const SubCategoriesForm = React.lazy(() =>
  import('./pages/admin pages/subcategory/SubCategoriesForm'),
)
const ManageJobs = React.lazy(() => import('./pages/admin pages/ManageJobs'))
const LeadForm = React.lazy(() => import('./components/sectionForms/LeadForm'))
const AddSection = React.lazy(() => import('./pages/admin pages/Section/AddSection'))
const Home = React.lazy(() => import('./pages/Home'))
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'))
const RegsiterForm = React.lazy(() => import('./components/RegsiterPage'))

const RefundPolicy = React.lazy(() => import('./components/RefundPolicy'))
const BlogPage = React.lazy(() => import('./components/BlogPage'))
const CookiePolicy = React.lazy(() => import('./components/CookiePolicy'))
const TermsAndCondition = React.lazy(() => import('./components/TermsAndCondition'))
const Career = React.lazy(() => import('./components/Career'))
const HelpAndSupport = React.lazy(() => import('./components/HelpAndSupport'))
const About = React.lazy(() => import('./components/About'))
const HowItWorks = React.lazy(() => import('./components/HowItWorks'))
const HomeLayout = React.lazy(() => import('./layout/HomeLayout'))
const CCTVInstallation = React.lazy(() => import('./pages/CCTVInstallation'))

const App = () => {
  const { serverMessage, statusCode } = useSelector((state) => state.layout)
  const { pageCategories, location, loading } = useSelector((state) => state.categories)
  const { loading: UserLoading } = useSelector((state) => state.user)
  const { loading: SectionsLoading } = useSelector((state) => state.sections)
  const dispatch = useDispatch()
  useEffect(() => {
    if (statusCode == 200) {
      toast.success(serverMessage)
    } else if (statusCode && statusCode !== 200) {
      toast.error(serverMessage)
    }
    dispatch(pageCategoriesList())
    dispatch(setServerMessagestt(null))
  }, [serverMessage, statusCode, location])

  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Suspense fallback={loading}>
        {loading || UserLoading || SectionsLoading ? (
          <div>
            <Box sx={{ width: '100%' }}>
              <LinearProgress style={{ height: '7px' }} />
            </Box>
          </div>
        ) : null}


        <Routes>
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />

          <Route exact path="/thank-you" strict name="thank-you" element={<Welcome />} />
          <Route path="/" name="Home Layout" strict element={<HomeLayout />}>
            <Route index name="Home" element={<Home />} />
            <Route
              exact
              path="privacypolicy"
              strict
              sensitive={false}
              name="privacypolicy"
              element={<PrivacyPolicy />}
              />
            <Route
              exact
              path="registerpage"
              strict
              sensitive={false}
              name="registerpage"
              element={<RegsiterForm />}
              />
            <Route
              exact
              path="refundpolicy"
              strict
              name="refundpolicy"
              element={<RefundPolicy />}
              />
            <Route
              exact
              path="cookiepolicy"
              strict
              name="cookiepolicy"
              element={<CookiePolicy />}
              />
            <Route
              path="terms-conditions"
              name="terms-conditions"
              exact
              strict
              element={<TermsAndCondition />}
              />
            <Route path="career" strict exact name="career" element={<Career />} />
            <Route path="support" strict exact name="support" element={<HelpAndSupport />} />
            <Route path="about-us" strict exact name="about-us" element={<About />} />
            <Route path="blog" strict exact name="about-us" element={<BlogPage />} />

            <Route path="how-it-works" strict exact name="how-it-works" element={<HowItWorks />} />

            <Route path="login" strict exact name="login" element={<Login />} />
            <Route path="register" strict exact name="register" element={<Register />} />

            <Route path="blogs/:slug" strict exact name="about-us" element={<BlogDetails />} />
            {pageCategories &&
              pageCategories.map((item, i) => (
                <Route
                  key={i}
                  path={`${item?.slug?.toLowerCase()}-in-${location?.toLowerCase()}`}
                  name={`${item?.slug}`}
                  element={<CCTVInstallation />}
                />
              ))}
            <Route exact path="*" name="Page Loading" element={<Home />} />
          </Route>

          {/* Admin */}
          <Route exact path="/admin" name="admin" element={<DefaultLayout />}>
            <Route index path="dashboard" name="dashboard" element={<Dashboard />} />
            <Route path="addCategories" name="Add Categories" element={<CategoriesForm />} />
            <Route path="editCategory/:id" name="Edit Categories" element={<CategoriesForm />} />
            <Route path="subCategorylist/:id" name="Sub Categories" element={<SubCategories />} />
            <Route path="editVendor/:id" name="Edit Vendor" element={<VendorForn />} />
            <Route path="addVendor" name="Add Vendor" element={<VendorForn />} />
            <Route path="editBlog/:id" name="Edit Blog" element={<BlogForn />} />
            <Route path="addBlog" name="Add Blog" element={<BlogForn />} />
            <Route path="recharge" name="Recharge" element={<RechargeForm />} />
            <Route path="change-password" name="Change Password" element={<PasswordForm />} />
            <Route path="subcategories" name="SubCategories" element={<SubCategories />} />
            <Route
              path="addSubCategories"
              name="Add Sub Categories"
              element={<SubCategoriesForm />}
            />
            <Route
              path="editSubCategory/:id"
              name="Edit Sub Categories"
              element={<SubCategoriesForm />}
            />
            <Route path="categories" name="Categories" element={<Categories />} />
            <Route path="section/lead" name="Section" element={<Lead />} />
            <Route path="section/leadForm" name="Section" element={<LeadForm />} />
            <Route path="section/manageJobs" name="Section" element={<ManageJobs />} />
            <Route path="section/addAcceptedLead" name="Section" element={<AddAccesptedLead />} />
            <Route path="addSection" name="addSection" element={<AddSection />} />
            <Route path="Vendor" name="Vendor" element={<Vendor />} />
            <Route path="blog" name="Blog" element={<Blog />} />
            <Route path="editSection/:id" name="addSection" element={<AddSection />} />
            <Route path="sections" name="sections" element={<Sections />} />
            <Route path="citiesList" name="sections" element={<Cities />} />
            <Route path="addCity" name="city" element={<AddCity />} />
            <Route path="editCity/:id" name="city" element={<AddCity />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
