import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { get } from "src/utils/api_Helper";
import job from "../../assets/images/job.jpg";
import Select from "react-select";
import { CCol, CForm, CRow, CFormInput } from "@coreui/react";
import { registerInitialValues } from "src/utils/admin/constants";
import { useDispatch, useSelector } from "react-redux";
import { registerValidationSchema } from "src/utils/admin/validations";
import { register } from "src/redux/slices/userSlice";
import { categories } from "src/redux/slices/categorySlice";
import LoaderUI from "src/Loader/Loader";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
const Register = () => {
  const dispatch = useDispatch();
  const [dropdown2, setDropdown2] = useState([]);
  const categoriesData = useSelector((state) => state.categories);
  const { Listing } = useSelector((state) => state.user);
  const [categoryList, setCategoryList] = useState([]);
  const [msg, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);

  const [loader, setLoader] = useState(false);
  const submit = (values) => {
    setLoader(true);
    dispatch(register(values))
      .then((res) => {
        setLoader(false);
        if (res?.payload?.status == 200) {
          setOpen(true);
          setTimeout(() => {
            setOpen(false);
          }, 5000);
          setMessage(res.payload.message);
        } else {
          setOpenError(true);
          setTimeout(() => {
            setOpenError(false);
          }, 5000);
          setMessage(res?.payload?.response?.data?.message);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    get("/categoryList")
      .then((res) => {
        if (res.data.status == 200) {
          const arr = [];

          res.data.categoryList.map((item) => {
            if (item.parentId == null) {
              arr.push({
                label: item.name,
                value: item._id,
                price: item?.price,
              });
            }
          });

          setCategoryList([{ label: "Categories", options: arr }]);
        }
      })
      .catch((err) => {
        // toast.error(err.response.data.message)
      });

    setDropdown2([]);
    dispatch(categories());
    if (categoriesData.length > 0) {
      categoriesData?.categories.map((item) => {
        setDropdown2([...dropdown2, { label: item.name, value: item._id }]);
      });
    }
  }, []);

  const getValue = (ids) => {
    if (!ids || ids?.length === 0) {
      return `Per Lead cost for this category - Rs 0`;
    }
    const totalPrice = ids?.reduce((accumulator, currentId) => {
      const option = categoryList[0]?.options?.find(
        (option) => option?.value === currentId
      );
  
      if (option) {
        return accumulator + Number(option?.price);
      }
      return accumulator;
    }, 0);
  
    return `Per Lead cost for this category - Rs ${totalPrice}`;
  };

  return (
    <>
      {loader && <LoaderUI />}
      <section
        className="h-100 gradient-form"
        style={{
          backgroundImage: `url(${job})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container py-5 h-100 ">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-xl-12">
              <div
                className="card text-black"
                style={{
                  borderRadius: "20px",
                }}
              >
                <div className="row g-0">
                  <div className="col-lg-6">
                    <div className="card-body p-md-5 mx-md-4">
                      <div className="text-center">
                        <h4
                          className="mt-2 mb-5 pb-1 fw-bold pt-4"
                          style={{ fontSize: "36px" }}
                        >
                          Get More Leads and Grow Your Business
                        </h4>
                      </div>

                      <ul className="pl-4 pt-5">
                        <li
                          className="mb-4 fw-bold d-flex"
                          style={{ fontSize: "26px", color: "gray" }}
                        >
                          <BsFillCheckCircleFill
                            className="me-4 mt-1"
                            style={{ color: "#FF8A0B" }}
                          />
                          Get Quality Leads
                        </li>
                        <li
                          className="mb-4 fw-bold d-flex"
                          style={{ fontSize: "26px", color: "gray" }}
                        >
                          <BsFillCheckCircleFill
                            className="me-4 mt-1"
                            style={{ color: "#FF8A0B" }}
                          />
                          Real Time Leads Sent
                        </li>
                        <li
                          className="mb-4 fw-bold d-flex"
                          style={{ fontSize: "26px", color: "gray" }}
                        >
                          <BsFillCheckCircleFill
                            className="me-4 mt-1"
                            style={{ color: "#FF8A0B" }}
                          />
                          100% Real Leads
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* {msg !== '' ? msg : ''} */}
                  <div className="col-lg-6 mt-5">
                    <div className="card-body p-md-5 mx-md-4">
                      <div className="text-center">
                        <h4
                          className="mt-1 mb-1 py-4  "
                          style={{
                            backgroundColor: "black",
                            borderRadius: "15px 15px 0px 0px",
                            color: "white",
                            fontWeight: "bolder",
                          }}
                        >
                          Register Here
                        </h4>
                      </div>

                      <Formik
                        initialValues={registerInitialValues}
                        validationSchema={registerValidationSchema}
                        onSubmit={submit}
                      >
                        {({
                          handleSubmit,
                          errors,
                          touched,
                          setFieldValue,
                          values,
                        }) => {
                          return (
                            <CForm onSubmit={handleSubmit}>
                              <div className="mb-2">
                                <CRow className="mb-2">
                                  <CCol>
                                    <CFormInput
                                      name="name"
                                      id="name"
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("name", e.target.value)
                                      }
                                      className={`py-2${
                                        touched.name && errors.name
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      placeholder="Name"
                                    />
                                    {touched.name && errors.name ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "11px",
                                          position: "relative",
                                          left: "5px",
                                        }}
                                      >
                                        {errors.name}
                                      </div>
                                    ) : null}
                                  </CCol>
                                </CRow>
                                <CRow className="mb-2">
                                  <CCol>
                                    <CFormInput
                                      name="email"
                                      id="email"
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("email", e.target.value)
                                      }
                                      className={`py-2${
                                        touched.email && errors.email
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      placeholder="Email"
                                    />
                                    {touched.email && errors.email ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "11px",
                                          position: "relative",
                                          left: "5px",
                                        }}
                                      >
                                        {errors.email}
                                      </div>
                                    ) : null}
                                  </CCol>
                                </CRow>
                                <CRow className="mb-2">
                                  <CCol>
                                    <CFormInput
                                      name="contact"
                                      id="contact"
                                      type="text"
                                      onChange={(e) =>
                                        setFieldValue("contact", e.target.value)
                                      }
                                      className={`${
                                        touched.contact && errors.contact
                                          ? "border border-danger"
                                          : ""
                                      }`}
                                      placeholder="Contact"
                                    />
                                    {touched.contact && errors.contact ? (
                                      <div
                                        style={{
                                          color: "red",
                                          fontSize: "11px",
                                          position: "relative",
                                          left: "5px",
                                        }}
                                      >
                                        {errors.contact}
                                      </div>
                                    ) : null}
                                  </CCol>
                                </CRow>

                                {/* <Select
                                placeholder="Select City"
                                name="location"
                                id="location"
                                type="select"
                                className="basic-single mb-2"
                                classNamePrefix="select"
                                required
                                options={
                                  Listing
                                    ? Listing?.map((cities) => {
                                        return {
                                          label: cities.name,
                                          value: cities.name,
                                          _id: cities._id,
                                        }
                                      })
                                    : null
                                }
                                onChange={(value) => setFieldValue('location', value._id)}
                              />
                            </div>

                            <div className="mb-2">
                              <Select
                                placeholder="Select Category"
                                name="categoryId"
                                id="categoryId"
                                type="select"
                                className="basic-single"
                                classNamePrefix="select"
                                options={categoryList}
                                onChange={(value) => {
                                  setFieldValue('categoryId', value.value)
                                }}
                                
                              />*/}
                              </div>

                              <Select
                                placeholder="Select Cities"
                                name="location"
                                id="location"
                                isMulti
                                className="basic-multi-select mb-2"
                                classNamePrefix="select"
                                required
                                options={
                                  Listing
                                    ? Listing?.map((cities) => {
                                        return {
                                          label: cities.name,
                                          value: cities.name,
                                          _id: cities._id,
                                        };
                                      })
                                    : null
                                }
                                onChange={(values) => {
                                  const selectedValues = values.map(
                                    (value) => value._id
                                  );
                                  setFieldValue("location", selectedValues);
                                }}
                              />

                              <div className="mb-2">
                                <Select
                                  placeholder="Select Categories"
                                  name="categoryId"
                                  id="categoryId"
                                  isMulti
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  options={categoryList}
                                  onChange={(values) => {
                                    const selectedValues = values.map(
                                      (value) => value.value
                                    );
                                    setFieldValue("categoryId", selectedValues);
                                  }}
                                />
                              </div>

                               {values.categoryId !== "" && (
                                <CRow className="mb-2">
                                  <CCol>
                                    <CFormInput
                                      type="text"
                                      disabled={true}
                                      value={getValue(values.categoryId)}
                                    />
                                  </CCol>
                                </CRow>
                              )}
                              <div className="text-center pt-1 mb-5 pb-1">
                                <button
                                  className=" btn-block fa-lg gradient-custom-2 mb-3 w-100 py-2"
                                  type="submit"
                                  style={{
                                    background: "#FF8A0B",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    color: "white",
                                    border: "none",
                                  }}
                                >
                                  Register
                                </button>
                              </div>
                              <div style={{ "margin-top": "-64px" }}>
                                Are you already registered? If yes, then{" "}
                                <Link to="/login" style={{ color: "Blue" }}>
                                  Login Here
                                </Link>
                              </div>
                            </CForm>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Registered Successfully"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        <Dialog
          open={openError}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Registered Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </section>
    </>
  );
};

export default Register;
