import * as Yup from 'yup'

export const heroValidationSchema = Yup.object({
  seoTitle: Yup.string('Enter the category name')
    .min(4, 'Must be at least 4 characters long')
    .max(200, 'Must be less than 200 characters long')
    .required('This field is required'),
  seoDescription: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .required('This field is required'),
  title: Yup.string('Enter the category name')
    .min(4, 'Must be at least 4 characters long')
    .max(200, 'Must be less than 200 characters long')
    .required('This field is required'),
  description: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .required('This field is required'),
})
export const categoriesValidationSchema = Yup.object({
  name: Yup.string('Enter the category name')
    .min(4, 'Must be at least 4 characters long')
    .max(20, 'Must be less than 20 characters long')
    .required('This field is required'),
  location: Yup.string()
    .min(3, 'Must be at least 3 characters long')
    .max(20, 'Must be less than 20 characters long')
    .required('This field is required'),
})

export const subCategoriesValidationSchema = Yup.object({
  name: Yup.string('Enter the subcategory name')
    .min(4, 'Must be at least 4 characters long')
    .max(100, 'Must be less than 100 characters long')
    .required('This field is required'),
  location: Yup.array().min(1, 'This field is required').required('This field is required'),
})

const FILE_SIZE = 1024000 //1MB
export const aboutValidationSchema = Yup.object({
  heading: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  title: Yup.string('Enter the title')
    .min(4, 'Must be at least 4 characters long')
    .max(100, 'Must be less than 100 characters long')
    .required('This field is required'),
  description: Yup.string('Enter the about description')
    .min(4, 'Must be at least 4 characters long')
    .required('This field is required'),
  file: Yup.mixed().required('A file is required'),
})

export const sectionValidationSchema = Yup.object({
  location: Yup.string('Enter the location')
    .min(3, 'Must be at least 3 characters long')
    .required('This field is required'),
})

export const choseUsValidationSchema = Yup.object().shape({
  heading: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  title: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(100, 'Must be less than 100 characters long')
    .required('This field is required'),
  description: Yup.string()
    .min(5, 'Must be at least 5 characters long')
    .required('This field is required'),
  image: Yup.mixed().required('This field is required'),

  chooseUs: Yup.array().of(
    Yup.object().shape({
      label: Yup.string()
        .min(4, 'Must be at least 4 characters long')
        .max(100, 'Must be less than 100 characters long')
        .required('This field is required'),
      description: Yup.string()
        .min(4, 'Must be at least 4 characters long')
        .required('This field is required'),
    }),
  ),
})

export const servicesValidationSchema = Yup.object().shape({
  heading: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  heading2: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  service: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .min(4, 'Must be at least 4 characters long')
        .max(100, 'Must be less than 100 characters long')
        .required('This field is required'),
      description: Yup.string()
        .min(4, 'Must be at least 4 characters long')
        .required('This field is required'),
      image: Yup.mixed().required('This field is required'),
    }),
  ),
})

export const feebackValidationSchema = Yup.object().shape({
  heading: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  heading2: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  feedback: Yup.array().of(
    Yup.object().shape({
      subject: Yup.string()
        .min(4, 'Must be at least 4 characters long')
        .max(100, 'Must be less than 100 characters long')
        .required('This field is required'),
      userName: Yup.string()
        .min(3, 'Must be at least 3 characters long')
        .max(30, 'Must be less than 30 characters long')
        .required('This field is required'),
      comment: Yup.string()
        .min(1, 'Must be at least 1 characters long')
        .max(1000, 'Must be less than 1000 characters long')
        .required('This field is required'),
    }),
  ),
})
export const questionsValidationSchema = Yup.object().shape({
  heading: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  heading2: Yup.string()
    .min(4, 'Must be at least 4 characters long')
    .max(150, 'Must be less than 150 characters long')
    .required('This field is required'),
  image: Yup.mixed().required('This field is required'),

  questions: Yup.array().of(
    Yup.object().shape({
      question: Yup.string()
        .min(4, 'Must be at least 4 characters long')
        .max(200, 'Must be less than 200 characters long')
        .required('This field is required'),
      answer: Yup.string()
        .min(3, 'Must be at least 3 characters long')
        .required('This field is required'),
    }),
  ),
})

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid Email')
    .min(4, 'Must be at least 3 characters long')
    .required('This field is required'),
})
export const registerValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Must be at least 3 characters long')
    .required('This field is required'),
  email: Yup.string()
    .email('Enter a valid Email')
    .min(4, 'Must be at least 3 characters long')
    .required('This field is required'),
  contact: Yup.string()
    .required('This field is required')
    .min(10, 'Must be at least 10 characters long')
    .max(13, 'Must be less than 13 characters long'),
})
export const supportValidationSchema = Yup.object().shape({
  name: Yup.string().min(3, 'Must be at least 3 characters long'),
  email: Yup.string()
    .email('Enter a valid Email')
    .min(4, 'Must be at least 3 characters long')
    .required('This field is required'),
  contact: Yup.string()
    .required('This field is required')
    .min(10, 'Must be at least 10 characters long')
    .max(13, 'Must be less than 13 characters long'),
  message: Yup.string().max(1000, 'Must be less than 1000 characters long'),
})
