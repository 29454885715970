import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, post } from 'src/utils/api_Helper'

export const login = createAsyncThunk('user/login', async (body, { rejectWithValue }) => {
  try {
    const { data } = await post('/login', body)
    return data
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const forgotPasswordCall = createAsyncThunk(
  'user/forgotPassword',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await post('/forgotPassword', body)
      return data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
export const register = createAsyncThunk('user/register', async (body, { rejectWithValue }) => {
  try {
    const { data } = await post('/register', body)
    return data
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const getCallNow = createAsyncThunk('/getCallNow', async (body, { rejectWithValue }) => {
  try {
    const { data } = await post('/getCallNow', body)
    console.log(data, '===dat')
    return data
  } catch (err) {
    console.log(err, '==erro')
    return rejectWithValue(err)
  }
})

export const support = createAsyncThunk('user/support', async (body, { rejectWithValue }) => {
  try {
    const { data } = await post('/customerInfo', body)
    return data
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const cities = createAsyncThunk('user/cities', async (body, { rejectWithValue }) => {
  try {
    const { data } = await get('/getCity', body)
    return data
  } catch (err) {
    return rejectWithValue(err)
  }
})

const initialState = {
  message: null,
  loading: false,
  user: null,
  isAuth: false,
  price: null,
  Listing: null,
}

export const loginSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
      state.isAuth = payload ? true : false
    },
    setPayment: (state, { payload }) => {
      state.price = payload
    },
  },

  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true
    },
    [getCallNow.pending]: (state) => {
      state.loading = true
    },
    [register.pending]: (state) => {
      state.loading = true
    },
    [cities.pending]: (state) => {
      state.loading = true
    },

    [login.fulfilled]: (state, payload) => {
      const { token } = payload.payload

      localStorage.setItem('auth', JSON.stringify(token))

      state.user = payload.payload
      state.isAuth = true
      state.loading = false
    },
    [register.fulfilled]: (state, payload) => {
      if (payload.payload.status == 200) {
        state.user = payload.payload.user
      }
      state.loading = false
    },
    [getCallNow.fulfilled]: (state, payload) => {
      console.log(payload, '===payload')
      if (payload.payload.status == 200) {
        state.message = payload.payload.message
      }
      state.loading = false
    },
    [support.fulfilled]: (state, payload) => {
      if (payload.payload.status == 200) {
        state.message = payload.payload.message
      }
      state.loading = false
    },
    [cities.fulfilled]: (state, payload) => {
      if (payload.payload.status == 200) {
        state.Listing = payload.payload.citiesList
      }
      state.loading = false
    },
    [login.rejected]: (state) => {
      state.loading = false
    },
    [register.rejected]: (state) => {
      state.loading = false
    },
    [getCallNow.rejected]: (state) => {
      state.loading = false
    },
    [support.rejected]: (state) => {
      state.loading = false
    },
    [cities.rejected]: (state) => {
      state.loading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setPayment } = loginSlice.actions

export default loginSlice.reducer
