import React from 'react'
import { Spinner } from 'reactstrap'
import './Loader.css'
const LoaderUI = ({
  className = '',
  loader = true,
  overlay = true,
  overlayRadius = 0,
  FullWindow = true,
  color = 'primary',
  size = '',
  customSize = '',
}) => (
  <>
    <div
      className={`loaderWrapper d-flex align-items-center justify-content-center 
            ${className} 
            ${FullWindow ? 'position-fixed' : 'position-absolute'}
            `}
    >
      {overlay ? (
        <span className={`loaderOverlay position-absolute ${overlayRadius}`}></span>
      ) : null}
      {loader ? (
        <Spinner color={color} size={size} className={`position-relative large ${customSize}`} />
      ) : null}
    </div>
  </>
)

export default LoaderUI
