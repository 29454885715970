import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'src/utils/api_Helper'

export const pages = createAsyncThunk('pages/fetchpages', async (body, { rejectWithValue }) => {
  try {
    const response = await get(`/pageList?location=${body.location}&slug=${body.cateName}`)
    return response
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const pagesList = createAsyncThunk('pages/fetchpages', async (body, { rejectWithValue }) => {
  try {
    const response = await get(`/pageList?location=${body.location}&slug=${body.cateName}`)
    return response
  } catch (err) {
    return rejectWithValue(err)
  }
})

const initialState = {
  message: null,
  pages: null,
  loading: false,
}

export const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {},

  extraReducers: {
    [pages.pending]: (state) => {
      state.loading = true
    },
    [pages.fulfilled]: (state, payload) => {
      if ((payload.status = 200)) {
        state.pages = payload.payload.data
      }

      state.loading = false
    },
    [pages.rejected]: (state) => {
      state.loading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const {} = sectionsSlice.actions

export default sectionsSlice.reducer
