import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get, post } from 'src/utils/api_Helper'

export const pages = createAsyncThunk('pages/fetchPages', async ( { rejectWithValue }) => {
  try {
    const { data } = await get(`/allPages`)
    return data
  } catch (err) {
    return rejectWithValue(err)
  }
})

const initialState = {
  message: null,
  loading: 'aa',
  pages: [],
  editItem: null,

  location: [],
  category: null,
  categoryId: null,
  SEO: null,
}

export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setEditItem: (state, { payload }) => {
      state.editItem = payload
    },
    setLocation: (state, { payload }) => {
      state.location = payload
    },
    setCategory: (state, { payload }) => {
      state.category = payload
    },
    setCategoryId: (state, { payload }) => {
      state.categoryId = payload
    },
    setSEO: (state, { payload }) => {
      state.SEO = payload
    },
  },

  extraReducers: {
    [pages.pending]: (state) => {
      state.loading = true
    },

    [pages.fulfilled]: (state, payload) => {
      const body = payload.payload
      if ((body.status = 200)) {
        state.pages = body
      }

      state.loading = false
    },

    [pages.rejected]: (state) => {
      state.loading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEditItem, setCategory, setLocation, setCategoryId, setSEO } = pagesSlice.actions

export default pagesSlice.reducer
