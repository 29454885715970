import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'src/utils/api_Helper'

export const categories = createAsyncThunk(
  'categories/fetchCategories',
  async (body, { rejectWithValue }) => {
    try {
      const response = await get('/categoryList')
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const pageCategories = createAsyncThunk(
  'categories/fetchPageCategoriess',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await get('/pageCategoryList')
      return data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const pageCategoriesList = createAsyncThunk(
  'categories/fetchPageCategoriess',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await get('/pageCategoryList')
      return data
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const categoriesById = createAsyncThunk(
  'categories/fetchCategoriesById',
  async (body, { rejectWithValue }) => {
    try {
      const response = await get(`/categoriesById?id=${body}`)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

const initialState = {
  message: null,
  categoryBody: null,
  loading: false,
  categories: null,
  nestedCategories: null,
  location: '',
  pageCategories: null,
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategoryBody: (state, { payload }) => {
      state.categoryBody = payload
    },
    setCategories: (state, { payload }) => {
      state.categories = payload
    },
    setLocation: (state, { payload }) => {
      state.location = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
  },

  extraReducers: {
    [categories.pending]: (state) => {
      state.loading = true
    },
    [categoriesById.pending]: (state) => {
      state.loading = true
    },
    [pageCategories.pending]: (state) => {
      state.loading = true
    },
    [categories.fulfilled]: (state, payload) => {
      if ((payload.status = 200)) {
        state.categories = payload.payload.data.categoryList
      }

      state.loading = false
    },
    [pageCategories.fulfilled]: (state, payload) => {
      const body = payload.payload

      if (body.status == 200) {
        state.pageCategories = body.categoryList
      }

      state.loading = false
    },
    [categoriesById.fulfilled]: (state, payload) => {
      if (payload.payload.status == 200) {
        state.nestedCategories = payload.payload.data.categoriesList
      }

      state.loading = false
    },
    [categories.rejected]: (state) => {
      state.loading = false
    },
    [categoriesById.rejected]: (state) => {
      state.loading = false
    },
    [pageCategories.rejected]: (state) => {
      state.loading = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCategoryBody, setCategories, setLocation, setLoading } = categoriesSlice.actions

export default categoriesSlice.reducer
